.select {
  position: relative;
  min-width: 80px;

  &_active {
    overflow: revert;

    .search-container {
      visibility: visible !important;
      opacity: 1 !important;
    }

    .selected-item {
      path {
        stroke: var(--color-main);
      }

      .select-arrow-icon {
        transform: rotate(180deg);

        path {
          stroke: var(--color-black) !important;
        }
      }
    }
  }

  &.dark-style {
    .selected-item {
      border: 1px solid #3A3A3A;
      background: #212121;

      .short-name {
        color: var(--color-white);
        white-space: nowrap;
      }
    }

    .search-items {
      border: 1px solid #3A3A3A !important;
      background: #212121 !important;

      .short-name {
        color: var(--color-white);
      }
    }
  }

  border-radius: 8px;
  transition: border-color 0.3s ease;
  
  &:hover {
    border-color: var(--color-main) !important;
  }

  .selected-item {
    display: flex;
    align-items: center;
    max-height: 42px;
    min-height: 42px;
    justify-content: space-between;
    background-color: var(--input-bg-color);
    border-radius: 8px;
    border: 1px solid var(--input-border-color);
    box-sizing: border-box;
    padding-right: 10px;
    outline: none;
    cursor: pointer;

    .select-arrow-icon {
      flex: 1 0 15px;

      &:hover path {
        stroke: var(--color-black);
      }

      path {
        stroke: #3A3A3A;
      }
    }

    .select-remove-icon {
      display: flex;
      width: 7px;
      height: 7px;
      cursor: pointer;
      padding: 15px 5px;
      box-sizing: content-box;

      &:hover path {
        stroke: var(--color-black);
      }

      path {
        stroke: #3A3A3A;
        stroke-width: 2px;
      }
    }

    .select-option-icon {
      box-sizing: content-box;

      &:hover path {
        stroke: var(--color-black);
        fill: var(--color-black);
      }

      path {
        stroke: #3A3A3A;
        fill: #3A3A3A;
        stroke-width: 2px;
      }
    }

    time {
      color: var(--color-main);
      font: 12px "Inter-Regular", Arial, sans-serif;
      min-width: 35px;
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }

  .search-container { 
    position: absolute;
    top: 48px;
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 10000;
    width: 100%;
    border-radius: 8px;
    overflow: auto;

    &.position-bottom {
      top: unset;
      bottom: 50px;
    }

    .search-form { 
      margin-bottom: 5px;
     
      input {
        border: 1px solid var(--input-border-color) !important; 
      }

      &.search-dark-style {
        .input-text { 
          input[type='text'] { 
            border: 1px solid #3A3A3A;
            background: #212121;
            color: var(--color-black);
          }
        }
      }

      .input-text { 
        input[type='text'] { 
          width: 100%;
          border-radius: 8px;
          background: var(--input-bg-color);
          padding: 15px;
          border: none;
          box-sizing: border-box;
          outline: none;
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
        }
      }
    }

    .delimiter {
      margin: 5px 0;
      background-color: #D9D9D9;
    }

    .search-items { 
      /* stylelint-disable declaration-block-no-redundant-longhand-properties */
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 220px;
      background-color: var(--input-bg-color);
      border-radius: 8px;
      border: 1px solid var(--input-border-color);
      padding: 5px;

      @media (width <= 976px) {
        max-height: 150px;
      }   
      
      @media (width <= 500px) {
        max-height: 110px;
      }

      span {
        display: flex;
      }

   
      .item-content { 
        cursor: pointer;
        border-radius: 8px;
        font: 13px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);


        span {
          color: var(--color-black) !important; 
        }
        
        &:hover {
          background-color: var(--color-orange);
          color: var(--white);

          span {
            color: var(--white) !important; 
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .option-container {
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 10000;
    width: 100%;
    border-radius: 8px;
    overflow: auto;
    background-color: var(--multiselect-bg-color);

    .option-container-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      background-color: var(--multiselect-bg-color);
      padding: 5px;
      overflow-x: hidden;

      input {
        width: 100%;
      }

      .option-input-wrapper {
        display: flex;
        align-items: center;
        gap: 5px;
        background-color: var(--input-bg-color);
        border-radius: 8px;
        padding: 10px;

        label {
          font: 12px "Manrope-Regular", Arial, sans-serif;
          color: #5A5A5A;
        }

        input {
          background-color: transparent;
          border: none;
          outline: none;
          color: var(--color-black);
          font: 12px "Manrope-Regular", Arial, sans-serif;
        }
      }
    }
  }
}

.item-content { 
  font: 13px 'Inter-Regular', Arial, sans-serif;
  color: var(--color-black);
  width: 100%;

  .select-item {
    display: flex;
    align-items: center;
    gap: 7px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.sub-account-select-item {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 10px 0 10px 10px;

  .item-spot-wallet {
    font: 11px "Inter-Regular", Arial, sans-serif;
    color: #838583;
    margin-left: auto;
    margin-right: 10px;
  }

  img {
    width: 20px;
    height: 20px;
  }

  &.lock {
    color: gray;
    cursor: auto;
  }
}

html[data-theme='light'] {
  .option-container {
    background-color: var(--multiselect-bg-color);
  }

  .option-container-wrapper {
    .option-input-wrapper {
      background-color: var(--white) !important;
  
    }
  }

}
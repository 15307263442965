$border-color: var(--input-border-color);

.assetsTableAdaptive {
  margin-bottom: 40px;

  header {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 44px;
    z-index: 10;
    
    h3 {
      font: 18px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);

      .assetsCount {
        font: 13px "Inter-Medium", Arial, sans-serif;
        color: var(--color-black);
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease;

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      .actionBtn {
        display: flex;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: transform 0.2s ease;
      
        &:active {
          transform: scale(0.9);
        }
      
        @media (hover: hover) {
          &:hover svg path {
            stroke: var(--color-orange);
          }
        }
      
        & path {
          stroke: var(--color-black);
        }
      }
      
    }
  }

  .search {
    background-color: var(--card-bg-color);
    border-radius: 8px;
    margin-bottom: 20px;
    visibility: hidden;
    opacity: 0;
    margin-top: -50px;
    transition: all 0.3s ease;
    z-index: 5;

    input {
      background: transparent;
      font: 11px "Inter-Regular",Arial, sans-serif;
      color: var(--color-black);
      border: 1px solid $border-color;

      &:focus-visible {
        border-color: var(--color-main);
      }
    }

    &.show {
      visibility: visible;
      opacity: 1;
      margin-top: 0;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 775px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }
  }
}

.loadingWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.drawer {
  [class*="MuiPaper-root"] {
    padding: 35px 25px 10px !important;
  }


  [class*="root-MuiModal-backdrop"] {
    backdrop-filter: blur(3px);
  }


  [class*="MuiPaper-root"] {
    background-color: var(--input-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px 25px;
    margin: 0 15px;
  }

  .filterWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    scrollbar-width: none; /* Firefox */
    margin-bottom: 20px;
  
    &::-webkit-scrollbar {
      display: none; /* Safari и Chrome */
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5px;

      .closeBtn {
        &::before {
          background: var(--color-black);
        } 
        
        &::after {
          background: var(--color-black);
        }
      }

      h3 {
        font: 18px "Inter-SemiBold", Arial, sans-serif;
        color: var(--color-black);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .sortItemsContainer {
        max-height: 116px;
      }

      .typesItemsContainer {
        max-height: 185px;
      }

      .selectItem {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-black);
        width: 100%;
      }

      [class*="data-picker"] {
        max-width: 100%;
      }
    }
  }
}
.languageContainer {
  position: absolute;
  top: 20px;
  right: 20px;

  .btnContainer {
    position: relative;
  }

  .language {
    display: inline-block;
    font: 14px "Inter-Regular", Arial, sans-serif;
    padding: 10px;
    background-color: var(--color-black);
    width: max-content;
    border-radius: 8px;
    color: var(--color-white);
    cursor: pointer;
    transition: background-color 0.3s ease;
    
    &:hover {
      background: var(--color-orange);
      color: var(--white);
    }

    &:focus-visible {
      background: var(--color-orange);
      color: var(--white);
    }
  }

  .list {
    margin-top: 5px;
    padding: 10px 0;
    background-color: var(--color-black-1e);
    color: var(--color-white);
    border-radius: 10px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 150px;
    z-index: 100;

    li {
      cursor: pointer;
      padding: 8px 10px;
      margin: 0 5px;
      color: var(--white);
      transition: all 0.3s ease;
      border-radius: 10px;
      outline: none;
      font: 14px "Inter-Regular", Arial, sans-serif;
      
      &:hover ,
      &:focus-visible {
        background-color: var(--color-orange);
        margin: 0 5px;
        color: var(--white);
      }

      & button {
        cursor: pointer;
        color: inherit;
        width: 100%;
        text-align: left;
      }
    }
  }
}

.position {
  position: relative !important;
  top: 0;
  right: 0;
}

html[data-theme='dark'] {
  .list {
    box-shadow: 2px 0 4px -1px rgb(0 0 0 / 55%);
  }
}
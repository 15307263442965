.openPositions {
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .navigation {
      .navList {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (width <= 576px) {
          gap: 10px;
        }

        button {
          display: flex;
        }
      }

      .navItem {
        font: 25px "Inter-Medium", Arial, sans-serif;
        color: #9C9C9C;

        @media (width <= 576px) {
          font-size: 16px;
        }

        &:hover {
          color: var(--color-black);
        } 

        &.activeNav {
          font: 25px "Inter-Medium", Arial, sans-serif;
          color: var(--color-black);

          @media (width <= 576px) {
            font-size: 18px;
          }
        }
      }

      .navItemDivider {
        font: 16px "Inter-SemiBold", Arial, sans-serif;
        color: #9C9C9C;
      }
    }

    .actionsOptions {
      display: flex;
      align-items: center;
      gap: 10px;
        
      button {
        display: flex;
        padding: 5px;
        border-radius: 50%;

        &:hover {
          background: var(--option-btn-bg);

          svg path {
            fill: var(--color-black);
          }

          svg rect {
            fill: var(--color-black);
          }

          svg circle {
            fill: var(--color-black); 
          }
        }
          
      }
    }  
    
    .options {
      position: absolute;
      top: 30px;
      right: 0;
      padding: 15px 25px;
      min-width: 250px;
      background-color: #222;
      border-radius: 8px;
      z-index: 100;
      display: none;
      opacity: 0;
      transition-duration: 0.2s;
      transition-behavior: allow-discrete;

      &.visible {
        display: block;
        opacity: 1;

        @starting-style {
          opacity: 0;
        }
      }

      .optionsTitle {
        color: var(--white);
        font: 13px "Inter-Medium", Arial, sans-serif;
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            span {
              color: var(--color-main);
            }
          }

          input {
            background: #3A3A3A;
            border: none;

              
            &:hover {
              border: 1px solid var(--color-main);
            }

            &:focus-visible {
              border: 1px solid var(--color-main);
            }

            &:checked {
              background: var(--color-main);
            }

            &:checked::before {
              content: "✓";
              position: absolute;
              top: 50%;
              left: 48%;
              transform: translate(-50%, -50%);
              color: var(--white); 
              font: 12px "Inter-SemiBold", Arial, sans-serif;
            }
          }

          span {
            color: var(--white);
            font: 13px "Inter-Medium", Arial, sans-serif;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    .header{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--input-border-color);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      padding-right: 20px;
      height: 56px;

      @media (width <= 976px) {
        overflow: auto;
        overflow-y: hidden;
      }

      .filters {
        display: flex;
        align-items: center;
        margin-left: -1px;
        margin-right: 10px;
      
        button {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          padding: 15px 30px;
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
          background-color: transparent;
          color: var(--color-black);
          transition: margin 0.3s ease;
          border: 1px solid transparent;
          z-index: 5;
          min-height: 48px;

          @media (width <= 500px) {
            padding: 10px 20px;
          }

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            border-radius: 5px;
            font: 12px "Inter-regular", Arial, sans-serif;
            color: var(--white);
          }
        }

        button:nth-child(2) {
          margin-left: -15px;

          span {
            background-color: var(--color-main);
          }
        }

        button:nth-child(3) {
          margin-left: -15px;

          span {
            background-color: var(--color-orange);
          }
        }

        button:hover {
          position: relative;
          background-color: var(--color-white);
          border: 1px solid #E9E9E9;
          z-index: 10;

          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%; 
            height: 3px; 
            background-color: #FFF; 
          }
        }

        button:focus {
          position: relative;
          background-color: var(--color-white);
          border: 1px solid #E9E9E9;
          z-index: 10;

          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%; 
            height: 3px; 
            background-color: #FFF; 
          }
        }

        button.activeFilter {
          position: relative;
          background-color: var(--color-white);
          border: 1px solid #E9E9E9;
          box-shadow: 2px 0 5px -1px rgb(173 173 173 / 55%);
          z-index: 10;
          margin-left: 0;

          &::before {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%; 
            height: 3px;
            background-color: #FFF; 
          }
        }
      }

      .actions {
        display: flex;
        gap: 10px;

        .windows {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-left: 10px;
          
          @media (width <= 976px) {
            display: none;
          }

          button {
            display: flex;
            align-items: center;
            height: max-content;
          }

          button.active {
            svg path {
              stroke: var(--color-black);
            }
          }

          button svg path {
            stroke: gray;
          }
                
          button:hover {
            svg path {
              stroke: var(--color-black);
            }
          }
        }

        .tableActions {
          display: flex;
          gap: 10px;
        }
      }
    }

    .bodyCard {
      border-radius: 0 0 16px 16px;
      background: var(--table-bg-color);
      width: 100%;
      height: 100%;
      overflow: auto;
      
      .positionCard {
        align-self: normal;
      }

      .emptyTable {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        text-align: center;
        font: 16px "Inter-Medium", Arial, sans-serif;
        min-height: 168px;

        .emptyText p{
          white-space: pre-wrap ;
        }
        
        @media (width >=500) {
          min-width: 475px;
        }
      }
    }

    .bodyList {
      border-radius: 0 0 16px 16px;
      background-color: var(--table-bg-color);
      width: 100%;
      height: 100%;
      min-height: 240px;
      overflow: auto;

      .emptyTable {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        text-align: center;
        font: 16px "Inter-Medium", Arial, sans-serif;
        min-height: 168px;

        .emptyText p{
          white-space: pre-wrap;
        }  
        
        .emptyTextError p{
          white-space: pre-wrap;
        }
      }
    }
  }
}

.tablePositions {
  width: 100%;
  border-spacing: 0 15px;
  border-collapse: separate;
  padding: 0 10px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  thead {
    position: sticky;
    top: 0;
    z-index: 99;
    text-align: left;
    border-spacing: 0 15px;
    border-collapse: separate;
  }

  tr {
    font: 11px "Inter-Regular", Arial, sans-serif;
    text-align: left;
    height: 42px;

    td:first-child {
      padding-left: 10px;
    }

    th:first-child {
      border-top-left-radius: 8px; 
      border-bottom-left-radius: 8px;
      padding: 0 10px;
    }

    th:last-child {
      border-top-right-radius: 8px; 
      border-bottom-right-radius: 8px;
      padding-right: 5px;
    }

  }

  th {
    height: 26px;
    cursor: pointer;
    font: 11px "Inter-Regular", Arial, sans-serif;
    color: var(--table-column-text-color) !important;
    text-align: left;
    vertical-align: middle;

    .checkbox {
      margin-left: 5px;
      background: #242424;
    }

    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  td {
    border-bottom: 1px solid var(--table-border-color);
    font: 13px "Inter-Regular", Arial, sans-serif;
    color: var(--color-black);
    vertical-align: middle;
    box-sizing: border-box;
  }

  .thWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;

    &.activeColumn {
      color: var(--color-main);

      svg path {
        stroke: var(--color-main);
      }
    }
  
    &:hover {
      color: var(--table-column-text-color-hover);

      svg path {
        stroke: var(--table-column-text-color-hover);
      }
    }
  }
}

.firstActionTd {
  color: var(--color-black);

  &.activeReduce {
    color: var(--color-main);
    border-color: var(--color-main);

    svg path {stroke: var(--color-main);}
  }

  &:hover {
    color: var(--color-main);

  }

  button {
    font: 13px "Inter-SemiBold", Arial, sans-serif;
    color: #212121;
  }
}

.secondActionTd {
  display: flex;
  gap: 5px;
  padding-right: 10px;
  color: var(--color-black);

  svg path {
    stroke: var(--color-black);
  }

  &:hover {
    color: var(--color-red);

    svg path {
      stroke: var(--color-red);
    }
  }

  button {
    font: 13px "Inter-SemiBold", Arial, sans-serif;
    color: #212121;
  }
}

.accountTd {
  margin-right: 10px;
}

.instrumentTd {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 25px;
  max-width: 150px;
  font: 12px "Inter-regular", Arial, sans-serif;
  cursor: pointer;
}

.exchangeTd {
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 25px;
    height: 25px;
  }
}

.reduceTd {
  margin-right: 10px;

  button {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &:hover {
    svg path {stroke: var(--color-main);}
  }

  svg {
    display: flex;

    path {stroke: var(--color-black);}
  }
}

.directionTd, .lastPriceTd, .pnlTd {
  color: var(--color-main);
  font: 13px "Inter-Regular", Arial, sans-serif;
}

.lastPriceTd{
  margin-right: 10px;
  white-space: nowrap;
  min-width: 72px;
}

.averagePriceTd, .pnlTd {
  margin-right: 10px;
  white-space: nowrap;
}

.pnlTd {
  min-width: 60px;
  color: var(--color-black);

  &.minus {
    color: var(--color-red);
  }  
  
  &.plus {
    color: var(--color-main);
  }
}

.directionTd {
  background-color: #00C37C47;
  width: max-content  ;
  color: #00C37C;
  border-radius: 4px;
  padding: 3px 7px;
  font: 12px "Inter-Regular", Arial, sans-serif;
}

.quantityTd {
  margin-right: 10px;
}
 
.directionTd.short {
  background-color: #FF131329;
  color: var(--color-red);
}

.reduceActionTd {
  display: flex;
  transition: transform 0.3s ease;

  svg path {
    stroke: var(--color-black);
  }
  
  &:active {
    transform: scale(0.8);
  }

  &:hover {
    svg path {
      stroke: var(--color-main);
    }
  }
}

.closeActionTd {
  transition: transform 0.3s ease;

  &:active {
    transform: scale(0.8);
  }

  svg path {
    stroke: var(--color-black);
  }

  &:hover {
    svg path {
      stroke: var(--color-red);
    }
  }
}

.checkbox {
  cursor: pointer;
  position: relative;
  width: 15px;
  height: 15px;
  background: var(--checkbox-bg-color);
  border-radius: 4px;
  transition: border-color 0.3s;
  border: 1px solid transparent;
  appearance: none;
  margin-right: 10px;
  margin-left: 5px;

  &:hover {
    border: 1px solid var(--color-main);
  }

  &:focus-visible {
    border: 1px solid var(--color-main);
  }

  &:checked {
    background: var(--color-main);
  }

  &:checked::before {
    content: "✓";
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-50%, -50%);
    color: var(--white); 
    font: 12px "Inter-SemiBold", Arial, sans-serif;
  }
}

.reduce {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: rgb(4 4 4 / 90%);
  border-radius: 8px;
  padding: 20px;
  z-index: 2000;

  img {
    width: 20px;
  }

  .pos {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -97%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgb(4 4 4 / 90%);
    clip-path: polygon(46% 61%, 93% 100%, 0% 100%);
  }

  .reduceBody {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--white);
    font: 16px "Inter-Regular", Arial, sans-serif;
    margin-bottom: 5px;
  }

  .calculateBody {
    position: relative;
    margin-top: 8px;

    .inputText {
      input {
        height: 42px;
        text-align: center;
        background: var(--color-white);
      }
    }
  }

  .incrementBtn, .decrementBtn {
    min-width: 40px;
    position: absolute;
    top: 0;
          
    &.decrementBtn {
      right: 0;
    }
  
    .btn {
      margin-top: 0 !important;
          
      button {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  
  .rangeslider {
    margin-top: 20px;
    position: relative;
  
    .track {
      width: 100%;
      height: 2px;
      background-color: #D9D9D9;
    }
  
    .filled-track {
      position: absolute;
      top: 0;
      width: 50%;
      height: 2px;
      background-color: #00AB6D;
  
      &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: -6px;
        display: black;
        width: 13px;
        height: 13px;
        border-radius: 100px;
        background-color: #00AB6D;
        cursor: pointer;
      }
    }
  
    .ruler {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
  
      span {
        font: 7px 'Inter-Light', Arial, sans-serif;
        color: #2C2C2C;
      }
    }
  }

  .tab {
    display: flex;
    gap: 5px;
    margin: 30px 0 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 13px 0;
      border-radius: 8px;
      border: 1px solid #3A3A3A;
      background-color: #1C1C1C;
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: #BDBDBD;
      width: 40px;
    }

    button.activeTab {
      background-color: var(--color-main);
      border: 1px solid var(--color-main);
      color: var(--color-white);
    }
  }

  .infoText {
    display: flex;
    margin-top: 10px;
    font: 10px "Inter-Regular", Arial, sans-serif;
    color: #BDBDBD;
    justify-content: left;
    width: 100%;
  }
}

.positionCardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(auto, auto);
  gap: 20px;
  padding: 20px;
  width: 100%;

  @media (width >= 4001px) {
    grid-template-columns: repeat(9, 1fr);
  }
  
  @media (width >= 3001px) and (width <= 4000px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (width >= 2421px) and (width <= 3000px) {
    grid-template-columns: repeat(6, 1fr);
  } 
  
  @media (width >= 2121px) and (width <= 2420px) {
    grid-template-columns: repeat(5, 1fr);
  }  
  
  @media (width >= 1921px) and (width <= 2120px) {
    grid-template-columns: repeat(4, 1fr);
  }
  
  @media (width <= 1920px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (width <= 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }  
  
  @media (width <= 576px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @keyframes increase {
    0% { transform: scale(1); }
    100% { transform: scale(1.01); }
  }
 
  .positionCardWrapper {
    display: flex;
    width: 100%;
    border: 1px solid transparent;

    &.active {
      border-radius: 16px;
      animation-name: increase; 
      animation-duration: 0.15s; 
      border: 1px solid var(--color-main);
    
    }

    .positionCard {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      border-radius: 16px;
      background: #F4F4F4;
      min-width: 250px;
      width: 100%;
      cursor: pointer;

      .box {
        display: flex;
        align-items: center;
        gap: 10px;

        div, span {
          white-space: nowrap;
        }
      

        .reduceTd, .closeActionTd {
          display: flex;
          align-items: center;
          gap: 5px;

          @media (width <= 976px) {
            display: none;
          }
        }     
        

        .exchange {
          display: flex;
          align-items: center;
          gap: 5px;
        
          img {
            width: 25px;
            height: 25px;
          }
        }

        .instrument {
          display: flex;
          align-items: center;
          gap: 10px;
          font: 15px "Inter-Medium", Arial, sans-serif;
          margin-right: auto;
        }
  
        .subAccount {
          display: flex;
          min-height: 30px;
          align-items: center;
          font: 13px 'Inter-Regular', Arial, sans-serif;
          color: var(--color-black);
          margin-right: auto;
        }
  
        .leverage {
          border-radius: 4px;
          background: #BFBFBF;
          color: var(--white);
          padding: 2px 5px;
        }

        .pnl {
          font: 20px "Inter-Medium", Arial, sans-serif;
          color: var(--color-black);

          &.minus {
            color: var(--color-red);
          }

          &.plus {
            color: var(--color-main);
          }
        }

        .side {
          background-color: var(--color-main);
          width: max-content;
          color: var(--white);
          border-radius: 4px;
          padding: 5px 7px;
          font: 13px "Inter-Regular", Arial, sans-serif;

          &.short {
            background: #FE394491;
          }
        }

        .quantity,
        .quantityDollars {
          display: flex;
          gap: 10px;
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);

          .symbol {
            color: inherit;
            font: inherit;
          }

          &.minus {
            color: var(--color-red);
          }
          
          &.plus {
            color: var(--color-main);
          }
        }

        .averagePrice {
          display: flex;
          align-items: center;
          gap: 10px;
          color: var(--color-black);
          font: 13px "Inter-Regular", Arial, sans-serif;
        }

        .lastPrice {
          color: var(--color-main);
          font: 13px "Inter-Regular", Arial, sans-serif;
        }
      }

      .boxMobile {
        display: flex;
        gap: 10px;
        width: 100%;

        .reduceTdMobile {
          background-color: var(--color-orange);
          transition: transfor, 0.3s ease;

          &:active {
            transform: scale(0.98);
          }
        } 
        
        .closeTdMobile {
          background-color: #2C2C2C;
          display: flex;
          align-items: center;
          gap: 5px;
          width: 100%;
          height: 100%;
          min-height: 42px;
          justify-content: center;
          color: var(--white);
          cursor: pointer;
          font: 13px "Inter-Regular", Arial, sans-serif;
          border-radius: 8px;
          padding: 0 10px;
          transition: all 0.3s ease;

          svg path {
            stroke: white;
          }
        }

        .reduceTdMobile, .closeTdMobile {
          display: flex;
          align-items: center;
          gap: 5px;
          border-radius: 8px;

          button {
            display: flex;
            align-items: center;
            gap: 5px;
            width: 100%;
            height: 100%;
            min-height: 42px;
            justify-content: center;
            color: var(--white);
            cursor: pointer;
            font: 13px "Inter-Regular", Arial, sans-serif;
            border-radius: 8px;
            padding: 0 10px;
            transition: all 0.3s ease;

            svg path {
              stroke: white;
            }
          }
          
          
          @media (width <= 976px) {
            display: flex;
            gap: 10px;
            width: 100%;
          }
        }
      }
    }
  }
}

html[data-theme='dark'] {
  .positionCardContainer {
    @media (width <= 976px) {
      gap: 10px;
      padding: 10px 2px;
    }  
  }

  .bodyCard {
    @media (width <= 976px) {
      background: transparent !important;
      border-top: 1px solid #1F1F1F;
    }  

    .positionCard {
      @media (width <= 976px) {
        background: #212121 !important;
      } 
    }
  }

  .header {
    @media (width <= 976px) {
      background: transparent !important;
    } 

    .activeFilter {
      @media (width <= 976px) {
        background: #212121 !important;
      } 
    }
  }
  
  .openPositions {
    .header {
      border: none;
      background-color: #343434;
      height: unset;

      .filters {
        button {
          border: none;
          z-index: 10;
        }

        button:nth-child(2) {
          box-shadow: 2px 0 4px -2px rgb(0 0 0 / 45%);
        }  

        button:nth-child(3) {
          box-shadow: 2px 0 4px -2px rgb(0 0 0 / 45%);
        }

        button:hover {
          position: unset;
          background-color: var(--color-black-13);
          border: none;
      

          &::before {
            height: 0;
          }
        }

        .activeFilter {
          border: none;
          box-shadow: 2px 0 4px -1px rgb(0 0 0 / 55%);
          background-color: var(--color-black-1e);
          z-index: 11;

          &::before {
            height: 0;
          }
        }
      }
    }

    .tablePositions,
    .positionCardWrapper {
      .reduce {
        .calculateBody {
          input {
            border: 1px solid #474747;
            color: var(--white);
          }

          .activeTab {
            color: var(--white);
          }
        }
      }
    }

    table {
      background: var(--color-black-1e);

      thead {
        tr {
          th {
            .checkbox {
              background: #D9D9D9;

              &:checked::before {
                color: var(--color-black-1e); 
              }
            }
          }
        }
      }
    }

    .positionCardWrapper {
      .positionCard {
        background: #343434;

        .box {
          .leverage {
            background: #737573;
          }
          
          .averagePrice {
            svg path {
              stroke: white;
            }
          }
        }
      }
    }
  }
}

html[data-theme='light'] {
  .positionCardContainer {
    @media (width <= 976px) {
      gap: 10px;
      padding: 10px;
    }  
  }

  .calculateBody {
    .inputText {
      input {
        background: #F4F6F4 !important;
      }
    }
  }
}

.drawer {
  [class*="root-MuiModal-backdrop"] {
    backdrop-filter: blur(3px);
  }


  [class*="MuiPaper-root"] {
    background-color: var(--input-bg-color);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 35px 25px;
    margin: 0 15px;
  }

  .reduceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  
    h1 {
      font: 18px "Inter-SemiBold", Arial, sans-serif;
      color: var(--color-black);
    }
  }
  
  .reduceModile {
    .reduceBody {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--color-black);
      font: 16px "Inter-Regular", Arial, sans-serif;
      margin-bottom: 20px;

      img {
        height: 15px;
      }
  
      .subAccount {
        margin-left: auto;
        font: 12px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
      }
    }
  
    .calculateBody {
      position: relative;
      margin-top: 40px;
  
      .inputText {
        input {
          height: 42px;
          text-align: center;
          background: #2D2D2D;
        }
      }

      [class*="reduce-slider_track"] {
        &::before {
          background-color: #DADADA !important;
        }
      }
    }
  
    .box {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
  
      .pnl {
        font: 20px "Inter-Medium", Arial, sans-serif;
        color: var(--color-black);
  
        &.minus {
          color: var(--color-red);
        }  
        
        &.plus {
          color: var(--color-main);
        }
      }
  
      .side {
        background-color: var(--color-main);
        width: max-content;
        color: var(--white);
        border-radius: 4px;
        padding: 5px 7px;
        font: 13px "Inter-Regular", Arial, sans-serif;
  
        &.short {
          background: #FE394491 !important;
        }
      }
  
      .quantity,
      .quantityDollars {
        display: flex;
        gap: 10px;
        font: 13px "Inter-Regular", Arial, sans-serif;
        color: var(--color-black);
  
        .symbol {
          color: inherit;
          font: inherit;
        }
  
        &.minus {
          color: var(--color-red);
        }
        
        &.plus {
          color: var(--color-main);
        }
      }
  
      .averagePrice {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--color-black);
        font: 13px "Inter-Regular", Arial, sans-serif;
  
        svg path {
          stroke: var(--color-black);;
        }
      }
  
      .lastPrice {
        color: var(--color-main);
        font: 13px "Inter-Regular", Arial, sans-serif;
      }
    }
  
    .incrementBtn, .decrementBtn {
      min-width: 40px;
      position: absolute;
      top: 0;
            
      &.decrementBtn {
        right: 0;
      }
    
      .btn {
        margin-top: 0 !important;
            
        button {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    
    .rangeslider {
      margin-top: 20px;
      position: relative;
    
      .track {
        width: 100%;
        height: 2px;
        background-color: #D9D9D9;
      }
  
      [class*="reduce-slider_track"] {
        &::before {
          background-color: #2D2D2D;
        }
      }
    
      .filled-track {
        position: absolute;
        top: 0;
        width: 50%;
        height: 2px;
        background-color: #00AB6D;
    
        &::after {
          content: '';
          position: absolute;
          top: -6px;
          right: -6px;
          display: black;
          width: 13px;
          height: 13px;
          border-radius: 100px;
          background-color: #00AB6D;
          cursor: pointer;
        }
      }
    
      .ruler {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
    
        span {
          font: 7px 'Inter-Light', Arial, sans-serif;
          color: #2C2C2C;
        }
      }
    }
  
    .tab {
      display: flex;
      gap: 5px;
      margin: 30px 0 10px;
  
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px 0;
        border-radius: 8px;
        border: 1px solid #3A3A3A;
        background-color: #1C1C1C;
        font: 13px "Inter-Regular", Arial, sans-serif;
        color: #BDBDBD;
        width: 100%;
      }
  
      button.activeTab {
        background-color: var(--color-main);
        border: 1px solid var(--color-main);
        color: var(--white);
      }
    }
  
    .infoText {
      display: flex;
      margin-top: 10px;
      font: 10px "Inter-Regular", Arial, sans-serif;
      color: #BDBDBD;
      justify-content: center;
      width: 100%;
    }
  }
}
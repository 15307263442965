.btn-close {
  position: relative;
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity ease 0.2s;

  &.close-theme {
    &::before, &::after {
      background: black;
    }
  }

  &:hover {
    opacity: 1;
  }

  &:focus-visible {
    opacity: 1;
  }

  &::before, &::after {
    content: '';
    position: absolute;
    bottom: 50%;
    display: block;
    width: 24px;
    height: 3px;
    background: var(--white);
    border-radius: 100px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8; 
  }
}

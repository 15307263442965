.statistics {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 200px;
  overflow: auto;
  height: 100%;
  flex-shrink: 0;

  @media (width <= 1405px) {
    padding-right: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    gap: 10px;
    overflow: unset;
  }

  .widgetContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100px;
    max-height: 200px;
    border: 1px solid var(--trade-table-border-color);
    border-radius: 16px;    
    padding: 20px;
    background: var(--referrals-bg);
    flex-shrink: 0;
    
    .title {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
      white-space: pre-wrap;
      margin-bottom: 10px;
      
    }

    .value {
      margin-top: auto;
      font: 1.5vw "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);  

      @media (width <= 1300px) {
        font-size: 30px;
      }
    }

    .valueToday {
      font: 13px "Inter-Regular", Arial, sans-serif;
      color: #5A5A5A;
    }

    .btnWithdraw {
      margin-top: 10px;
    }
  }
}
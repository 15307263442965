
.restoreButton {
  display: flex;
  align-items: center;
  gap: 5px;
  font: 10px "Inter-Light", Arial, sans-serif;
  cursor: pointer;
  color: var(--color-font-gray);
  margin-bottom: -15px;
  
  &:hover,
  &:focus-visible {
    color: var(--color-orange);

    & svg path {
      stroke: var(--color-orange);;
    }
  }
}
  
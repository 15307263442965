.userAgreement {
  display: flex;
  align-items: center;
  gap: 10px;
  font: 10px "Inter-Light";
  color: var(--color-font-gray);
  width: 300px;

  & a {
    font: 10px "Inter-Light";
    color: inherit;
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover {
      color: var(--color-orange);
    }

    &:focus-visible {
      color: var(--color-orange);

    }
  }

  .checkbox {
    cursor: pointer !important;

    input {
      border-color: var(--input-border-color);
      background-color: var(--input-bg-color);
    }

    svg path {
      fill: var(--color-main);
    }
  }
}
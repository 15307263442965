.subAccountCard {
  display: flex;
  flex-direction: column;
  border-radius: 17px;
  background: linear-gradient(227deg, #131313 0%, #2B2B2B 100%);
  cursor: pointer;
  transition: all 0.2s ease;
  will-change: transform;
  width: 100%;
  min-width: 260px;

  &:hover,
  &:focus-visible {
    will-change: transform;
    transform: translate(0, -1px);
    box-shadow: 0 11px 4px -7px rgb(34 60 80 / 10%);
  }

  .subAccountBody {
    display: flex;
    gap: 20px;
    background: var(--color-white);
    padding: 20px 25px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
    
  .title {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;

    h2 {
      font: 20px "Inter-Regular", Arial, sans-serif;
      color: var(--white);
      transition: all 0.2s ease;

      @media (width <= 1600px) {
        font: 14px "Inter-Regular", Arial, sans-serif;
      }

      @media (width <= 1200px) {
        font: 12px "Inter-Regular", Arial, sans-serif;
      }
    }

    img {
      height: 25px;  
    }

    svg {
      margin-left: auto;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    .totalQuantity {
      font: 18px "Inter-Regular", Arial, sans-serif;
      color: var(--color-black);
    }

    .accounts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      .quantity{
        display: flex;
        align-items: center;
        gap: 5px;

        span {
          font: 13px "Inter-Regular", Arial, sans-serif;
          color: var(--color-black);
          white-space: nowrap;
        }
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    place-self: center end;

    a {display: flex;}

    .action {
      display: flex;
      flex: 0 0 35px;
      padding: 5px;
      border-radius: 8px;
      background: #E4E4E4;

      &:hover {
        background: #131313;

        svg path {
          stroke: var(--white);
        }
      }

      svg {
        display: flex;
      }
    }

    button {
      margin-left: 10px;

      &:hover,
      &:focus-visible {
        svg {
          path,
          rect {
            fill: var(--color-black);
          }
        }
      }
    }
  }
}

html[data-theme='dark'] {
  .subAccountCard {
    background: #161616;

    .subAccountBody {
      background: #1D1D1D;
  
      .action {
        background: #333;
      }
    }
  }

}
.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(0 0 0 / 55%);

  &.blur {
    will-change: backdrop-filter;
    backdrop-filter: blur(3px);
  }

  [class*="btn-close"] {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 100;
    opacity: 0.5;

    &::before, &::after  {
      width: 20px;
      height: 1px;
      background: var(--color-black) !important;
    }

    &:active {
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.overlay {
  position: absolute;
  inset: 0;
  width: max-content;
  height: max-content;
  max-height: 90vh;
  margin: auto;
  z-index: 1000;
  background-color: var(--modal-bg-color);
  border: 1px solid var(--input-border-color);
  padding: 21px;
  border-radius: 8px;
  overflow-y: auto;
  box-sizing: border-box; 
  opacity: 0;
  animation: fade-in-opacity 0.3s ease-out forwards;
}

@keyframes fade-in-opacity {
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}

.modalContent {
  position: relative;
  z-index: 1010;
}

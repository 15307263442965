.btn {
  width: 100%;
  height: 42px;

  .btn-download {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 5px;
    font: 14px "Inter-Regular", Arial, sans-serif;
    z-index: 1;
    animation-name: progress;
    animation-duration: 0.3s;
    animation-direction: alternate;

    svg {
      width: 20px;

      path, polyline, line {
        stroke: var(--white);
      }
    }

  }

  button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    min-height: 42px;
    justify-content: center;
    color: var(--white);
    cursor: pointer;
    font: 13px "Inter-Regular", Arial, sans-serif;
    border-radius: 8px;
    padding: 0 10px;
    transition: background-color 0.2s ease;
    white-space: nowrap;
    overflow: hidden;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.8; 
    }

    &:active {
      transform: scale(0.99);
    }

    &.download {
      background-color: #3A3A3A !important;
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: #FF8605;
      border-radius: 8px;
      transition: all 0.1s ease-in-out;
    }


    svg path {
      transition: all 0.3s ease;
    }

    &.green {
      background-color: #00C37C;
      color: var(--white);

      &:hover, &:focus-visible {
        background-color: #00A468;
      }

    }   
    
    &.green-linear {
      background: linear-gradient(90deg, rgb(0 216 162 / 10%) 0%, #007256 100%);
      color: var(--white);

      &:hover, &:focus-visible {
        background: linear-gradient(90deg, rgb(0 216 162 / 10%) 0%, #007256 100%);
        opacity: 0.8;
      }
    }

    &.gray {
      background-color: #676767;

      &:hover, &:focus-visible {
        background-color : #151515;
      }
    }    
    
    &.red {
      background-color: #FE3944;

      &:hover, &:focus-visible {
        background-color : #E71F29;
      }
    }

    &.whiteGray {
      background: #D1D1D1;

      &:hover, &:focus-visible {
        background-color: #676767;
      }
    }

    &.black {
      display: flex;
      align-items: center;
      gap: 5px;
      background-color: #2C2C2C;

      svg path {
        stroke: var(--white);
      }

      &:hover, &:focus-visible {
        background-color: black;
      }
    }

    &.orange {
      background-color: #FF8605;

      &:hover, &:focus-visible {
        background-color: #D86F00;
      }
    }

    &.white {
      background-color: var(--white);
      color: black;

      &:hover, &:focus-visible {
        background-color: #F2F2F2;
      }
    }
  }
}


@keyframes progress {
  0% {
    transform: translate(0, 30px);
  }

  100% {
    transform: translate(0, 0);
  }
}